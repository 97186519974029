import React, { Fragment, useEffect, useRef, useState } from 'react';
import Button from '../../library/Button/button';
import InputField from '../../library/inputField/inputField';
import Label from '../../library/label/label';
import { ConditionalStyleComponent, DynamicHeader } from '../ConditionalStyle';
import PartnerOrganisationDomains from './PartnerOrganisationDomains';

const PartnerCompanyDetails: React.FC<any> = (props: any) => {
  interface IError {
    errorMessage: string | null;
    errorConfirmMessage: string | null;
  }
  // State for partner company details
  const [isPartnerCompanyNameValid, setIsPartnerCompanyNameValid] = useState<boolean>(false);
  const [isCompanyHeadQuarterAddressValid, setIsCompanyHeadQuarterAddressValid] =
    useState<boolean>(false);
  const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false);

  // States for enabling the partner company details
  const [isPartnerCompanyNameEnable, setPartnerCompanyNameEnable] = useState<boolean>(false);
  const [isCompanyHeadQuarterAddressEnable, setCompanyHeadQuarterAddressEnable] =
    useState<boolean>(false);
  const [isDescriptionEnable, setDescriptionEnable] = useState<boolean>(false);

  // Error States
  const [errorPartnerCompanyName, setErrorPartnerCompanyName] = useState<IError>({
    errorMessage: null,
    errorConfirmMessage: null
  });
  const [errorCompanyHeadQuarterAddress, setErrorCompanyHeadQuarterAddress] = useState<IError>({
    errorMessage: null,
    errorConfirmMessage: null
  });
  const [errorDescription, setErrorDescription] = useState<IError>({
    errorMessage: null,
    errorConfirmMessage: null
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [domains, setdomains] = React.useState<string[]>([]);

  // Refs for input fields
  const clickRefPartnerCompanyName: any = useRef(null);
  const clickRefCompanyHeadQuarterAddress: any = useRef(null);
  const clickRefDescription: any = useRef(null);

  // Validation functions
  const isValidPartnerCompanyName = (partnerCompany: string): boolean =>
    partnerCompany.trim().length > 1 && partnerCompany.trim().length <= 40;

  const isValidCompanyHeadQuarterAddress = (companyHeadQuarter: string): boolean => {
    const regex = /^(?!\s*$).+/;
    return regex.test(companyHeadQuarter);
  };

  const isValidDescription = (description: string): boolean => {
    const regex = /^(?!\s*$).+/;
    return regex.test(description);
  };

  const validatePartnerCompanyName = () => {
    const inputValue = clickRefPartnerCompanyName.current?.value || '';
    if (!isValidPartnerCompanyName(inputValue)) {
      setIsPartnerCompanyNameValid(false);
      if (inputValue.trim().length === 0) {
        setErrorPartnerCompanyName({
          errorMessage: "Company name can't be empty.",
          errorConfirmMessage: null
        });
      } else {
        setErrorPartnerCompanyName({
          errorMessage: 'Company name should have at least 2 and max 40 characters.',
          errorConfirmMessage: null
        });
      }
    } else {
      setIsPartnerCompanyNameValid(true);
      setErrorPartnerCompanyName({ errorMessage: null, errorConfirmMessage: null });
    }
  };

  // UseEffect hooks to handle outside click events for various input fields and performs validation based on the enable flags.
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        (isPartnerCompanyNameEnable &&
          clickRefPartnerCompanyName.current &&
          !clickRefPartnerCompanyName.current.contains(event.target as Node)) ||
        (isCompanyHeadQuarterAddressEnable &&
          clickRefCompanyHeadQuarterAddress.current &&
          !clickRefCompanyHeadQuarterAddress.current.contains(event.target as Node)) ||
        (isDescriptionEnable &&
          clickRefDescription.current &&
          !clickRefDescription.current.contains(event.target as Node))
      ) {
        if (isPartnerCompanyNameEnable) {
          validatePartnerCompanyName();
        }

        if (isCompanyHeadQuarterAddressEnable) {
          const inputValue = clickRefCompanyHeadQuarterAddress.current.value || '';
          if (!isValidCompanyHeadQuarterAddress(inputValue)) {
            setIsCompanyHeadQuarterAddressValid(false);
            setErrorCompanyHeadQuarterAddress({
              errorMessage: "Company address can't be empty.",
              errorConfirmMessage: ''
            });
          } else {
            setIsCompanyHeadQuarterAddressValid(true);
            setErrorCompanyHeadQuarterAddress({ errorMessage: null, errorConfirmMessage: null });
          }
        }

        if (isDescriptionEnable) {
          if (!isValidDescription(clickRefDescription.current.value)) {
            setIsDescriptionValid(false);
            setErrorDescription({
              errorMessage: "Description can't be empty.",
              errorConfirmMessage: ''
            });
          } else {
            setIsDescriptionValid(true);
            setErrorDescription({ errorMessage: null, errorConfirmMessage: null });
          }
        }
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isPartnerCompanyNameEnable, isCompanyHeadQuarterAddressEnable, isDescriptionEnable]);

  // Event handlers for input changes
  const handleOnChangePartnerCompanyName = (event: any): any => {
    const inputValue = event.target.value;
    props.setpartnerCompanyName(inputValue);
    if (event.target.id === 'pcn') {
      if (inputValue.trim().length === 0) {
        setIsPartnerCompanyNameValid(false);
        setErrorPartnerCompanyName({
          errorMessage: "Company name can't be empty.",
          errorConfirmMessage: errorPartnerCompanyName.errorConfirmMessage
        });
      } else if (inputValue.trim().length < 2 || inputValue.trim().length > 40) {
        setIsPartnerCompanyNameValid(false);
        setErrorPartnerCompanyName({
          errorMessage: 'Company name should have at least 2 and max 40 characters.',
          errorConfirmMessage: errorPartnerCompanyName.errorConfirmMessage
        });
      } else {
        setIsPartnerCompanyNameValid(true);
        setErrorPartnerCompanyName({ errorMessage: null, errorConfirmMessage: null });
      }
    } else {
      setErrorPartnerCompanyName({
        errorMessage: errorPartnerCompanyName.errorMessage,
        errorConfirmMessage: null
      });
    }
  };

  const handleInputChangeCompanyHeadQuarterAddress = (event: any): any => {
    const inputValue = event.target.value;
    props.setcompanyHeadQuaterAddress(inputValue);

    if (!isValidCompanyHeadQuarterAddress(inputValue)) {
      setIsCompanyHeadQuarterAddressValid(false);
      setErrorCompanyHeadQuarterAddress({
        errorMessage: "Company address can't be empty.",
        errorConfirmMessage: ''
      });
    } else {
      setIsCompanyHeadQuarterAddressValid(true);
      setErrorCompanyHeadQuarterAddress({ errorMessage: null, errorConfirmMessage: null });
    }
  };

  const handleInputChangeDescription = (event: any): any => {
    const inputValue = event.target.value;
    const inputId = event.target.id;

    if (inputId === 'desc') {
      if (inputValue !== undefined) {
        const isValid = isValidDescription(inputValue);

        props.setdescription(inputValue);
        setIsDescriptionValid(isValid);
        setErrorDescription({
          errorMessage: null,
          errorConfirmMessage: errorDescription.errorConfirmMessage
        });
      }
    } else {
      props.setdescription(props.description);
      setErrorDescription({
        errorMessage: errorDescription.errorMessage,
        errorConfirmMessage: null
      });
    }
  };

  useEffect(() => {
    if (
      !props.partnerCompanyName &&
      props.partnerCompanyName.length < 2 &&
      props.partnerCompanyName.length > 40 &&
      !props.companyHeadQuaterAddress &&
      !props.description
    ) {
      setIsDisabled(true);
    }
  }, [props.companyHeadQuaterAddress, props.partnerCompanyName, props.description]);

  return (
    <Fragment>
      <ConditionalStyleComponent
        condition={!props.isSaveUserDetails && !props.isSaveUserPcd}
        trueStyle={{ display: 'block', marginTop: '72px' }}
        falseStyle={{
          display: 'block '
        }}
        isSaveUserDetails={false}
        isSaveUserPcd={false}
      >
        <DynamicHeader
          isSaveUserDetails={props.isSaveUserDetails}
          isSaveUserPcd={props.isSaveUserPcd}
          trueStyle={undefined}
          falseStyle={undefined}
          condition={''}
        >
          <ConditionalStyleComponent
            condition={props.isSaveUserDetails}
            trueStyle={{ display: 'block', textColor: 'white' }}
            falseStyle={{
              display: 'block',
              textColor: '#333'
            }}
            isSaveUserDetails={false}
            isSaveUserPcd={false}
          >
            Partner company details
            {props.isSaveUserPcd && <span> - </span>}
            {props.isSaveUserPcd ? `${props.partnerCompanyName}` : ''}
          </ConditionalStyleComponent>
          {props.isSaveUserDetails && props.isSaveUserPcd && (
            <span className="block float-right h-4 text-left">
              <span
                className="flot-left py-4 px-[5px] text-[white] inline-block relative -top-[49px] underline"
                onClick={(e) => {
                  props.setSaveUserPcd(false);
                }}
              >
                Edit
              </span>
              <span
                className="inline-block relative -top-[34px]"
                style={{ transform: 'translate(0%,1%)' }}
              >
                <span
                  className="bg-[100%] bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/BT_Iconography_Confirmation_message_white.png')] h-12 w-12 overflow-hidden inline-block"
                  style={{
                    backgroundPosition: '0 0',
                    backgroundSize: '100% 100%'
                  }}
                ></span>
              </span>
            </span>
          )}
        </DynamicHeader>
      </ConditionalStyleComponent>
      {props.isSaveUserDetails && !props.isSaveUserPcd && (
        <div className="block">
          <h3 className="pr-[25%] mt-[14px] mb-2.5 text-[21px] leading-[1.5em] text-[#333]">
            Please enter Partner company details.
          </h3>

          <div className="block">
            <div className="p-0 w-full float-left relative">
              <div className="w-full my-5 mx-0 relative table border-separate ">
                <Label
                  className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left required-field"
                  LabelText="Partner company name:"
                />

                <div className="px-0 w-[31.25%] table">
                  <InputField
                    maxLength={40}
                    id="pcn"
                    type="text"
                    className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                    value={props.partnerCompanyName}
                    onChange={handleOnChangePartnerCompanyName}
                    reference={clickRefPartnerCompanyName}
                    required={true}
                    style={
                      errorPartnerCompanyName.errorMessage == null
                        ? { border: '1px solid #666' }
                        : { border: '1px solid #e60014' }
                    }
                    onClick={() => {
                      setPartnerCompanyNameEnable(true);
                    }}
                  />
                  {errorPartnerCompanyName.errorMessage && (
                    <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                      <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                      <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                        <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                          {' '}
                          {errorPartnerCompanyName.errorMessage}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <PartnerOrganisationDomains
            domains={domains}
            onOrganisationDomainChange={(domains: string[]) => {
              setdomains(domains);
            }}
          />
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left required-field"
              LabelText="Company Head quarters address:"
            />
            <div className="px-0 w-[31.25%] table">
              <InputField
                maxLength={100}
                id="chqa"
                type="text"
                className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                value={props.companyHeadQuaterAddress}
                onChange={handleInputChangeCompanyHeadQuarterAddress}
                reference={clickRefCompanyHeadQuarterAddress}
                required={true}
                style={
                  errorCompanyHeadQuarterAddress.errorMessage == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
                onClick={() => {
                  setCompanyHeadQuarterAddressEnable(true);
                }}
              />
              {errorCompanyHeadQuarterAddress.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errorCompanyHeadQuarterAddress.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left required-field"
              LabelText=" Tell us something about your organization:"
            />

            <div className="px-0 w-[31.25%] table">
              <textarea
                id="desc"
                placeholder="Description of why you want to join the programme..."
                rows={4}
                defaultValue=""
                className="block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                value={props.description}
                onChange={handleInputChangeDescription}
                ref={clickRefDescription}
                required={true}
                style={
                  errorDescription.errorMessage == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
                onClick={() => {
                  setDescriptionEnable(true);
                }}
              />
              {errorDescription.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errorDescription.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="block"></div>
          <div className="my-5 mx-0 w-full" style={{ borderBottom: '#ddd solid 1px' }}></div>
          <div className="float-left">
            <Button
              className="bg-[white] text-black border-black py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block border leading-[1.168px]"
              onclick={() => {
                props.setSaveUserDetails(false);
              }}
              buttonText="Cancel"
            />
          </div>
          <div className="float-right">
            <ConditionalStyleComponent
              condition={
                props.partnerCompanyName &&
                props.companyHeadQuaterAddress &&
                props.description &&
                props.partnerCompanyName.length >= 2 &&
                isPartnerCompanyNameValid
              }
              trueStyle={{
                backgroundColor: '#000',
                color: 'white',
                border: 'black',
                paddingTop: '11px',
                paddingBottom: '11px',
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem',
                marginTop: '5px',
                marginBottom: '5px',
                marginLeft: '0',
                marginRight: '0',
                width: '100%',
                textAlign: 'center',
                height: '2.5rem',
                fontSize: '0.9rem',
                borderRadius: '0.375rem',
                display: 'inline-block'
              }}
              falseStyle={{
                backgroundColor: '#808080',
                cursor: 'not-allowed',
                color: 'white',
                border: '#808080',
                opacity: '0.5',
                paddingTop: '11px',
                paddingBottom: '11px',
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem',
                marginTop: '5px',
                marginBottom: '5px',
                marginLeft: '0',
                marginRight: '0',
                width: '100%',
                textAlign: 'center',
                height: '2.5rem',
                fontSize: '0.9rem',
                borderRadius: '0.375rem',
                display: 'inline-block'
              }}
              isSaveUserDetails={false}
              isSaveUserPcd={false}
            >
              <Button
                className={''}
                disabled={isDisabled}
                onclick={() => {
                  props.setSaveUserPcd(true);
                  props.updatePartnerDomains(domains);
                }}
                buttonText="Continue"
              />
            </ConditionalStyleComponent>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default PartnerCompanyDetails;
