import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import BtLogo from '../../library/Logo/BtLogo';
import ResponsiveMenu from '../ResponsiveMenu';
import { downloadFile } from '../../utils/Helper/downloadFiles';

// Header component for all other pages. This is the basic header which we are using for Home, Login, Register Pages respectively.
const WelcomeHeader: React.FC<any> = () => {
  // Use State to handle the header for mobile view. If it is true, then use will be able to see the header as a burger button.
  const [active, setActive] = useState<boolean>(false);

  const showMenu = (): void => {
    setActive(!active);
  };
  // Download UserGuideBook and script.
  const downloadUserGuide = () => {
    const fileUrl = '/SignInGuideBook.pdf';
    const fileName = 'SignInGuideBook'; // Set the desired file name
    downloadFile(fileUrl, fileName);
  };

  return (
    <Fragment>
      <div className="bg-bt-white w-full md:grid lg:grid lg:grid-cols-8 lg:gap-4 md:py-4 lg:py-4 py-1">
        <div className="lg:col-start-0 col-span-8 pr-4 flex flex-row justify-between items-center">
          <Link className="lg:max-h-12 " to="/" title="Home" rel="home">
            <BtLogo />
          </Link>
          <div className="text-white inline-flex p-3 hover:bg-gray-900 rounded md:hidden lg:hidden ml-auto hover:text-white outline-none nav-toggler">
            {!active ? (
              <div onClick={showMenu}>
                <div className="w-6 h-[3px] bg-black m-[7px] duration-500"></div>
                <div className="w-6 h-[3px] bg-black m-[7px] duration-500"></div>
                <div className="w-6 h-[3px] bg-black m-[7px] duration-500"></div>
              </div>
            ) : (
              <div onClick={showMenu}>
                <div
                  className="w-6 h-[3px] bg-black m-[7px] duration-500"
                  style={{
                    transform: 'translate(0, 9px) rotate(-45deg)'
                  }}
                ></div>
                <div className=" opacity-0 w-6 h-[3px] bg-black m-[7px] duration-500"></div>
                <div
                  className=" w-6 h-[3px] bg-black m-[7px] duration-500"
                  style={{
                    transform: 'translate(0, -11px) rotate(45deg)'
                  }}
                ></div>
              </div>
            )}
          </div>
          <div className="hidden md:flex flex-row lg:w-1/5 md:w-[87%] md:text-sm justify-between text-black font-bold md:pl-3.5">
            <Link className="ml-[1rem]" to={''} onClick={downloadUserGuide}>
              Sign-in GuideBook
              <svg
                className="w-4 h-4 ml-14 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1v12m0 0 4-4m-4 4L1 9"
                />
              </svg>
            </Link>
            <Link className="ml-[1rem]" to="/">
              About
            </Link>
            <Link to="/login">Sign-in</Link>
          </div>
        </div>
        <ResponsiveMenu showMenu={showMenu} active={active} />
      </div>
    </Fragment>
  );
};

export default WelcomeHeader;
