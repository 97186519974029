import React, { useState, Fragment, useEffect, useCallback } from 'react';
import CustomPopup from '../BtAdminPanel/CustomPopup';
import ImageTag from '../../library/ImageTag/ImageTag';
import edit from '../../assets/img/edit.png';
import { useNavigate } from 'react-router-dom';
import Form from '../../library/Form/Form';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import UpdateOrgUser from '../../utils/OrganizationUser/UpdateUser';
import { JobTitle, responseMessages } from '../../utils/Helper/enums';
import Button from '../../library/Button/button';

interface editUserProps {
  orgPartnerCompanyEmail: string | any;
  userName: string | any;
  orgJobTitle: string | any;
  orgPartnerCompanyName: string | any;
  userEmail: string | any;
  disabled: boolean;
  userPhone: string | any;
}
// Re-usable component for updating Organization users for a specific Organization Admin
export const EditUserForm: React.FC<editUserProps> = ({
  orgPartnerCompanyEmail,
  userName,
  orgJobTitle,
  orgPartnerCompanyName,
  userEmail,
  disabled,
  userPhone
}) => {
  // Interface for declaring data types
  interface formDetails {
    usersEmail?: string | null;
    name?: string | null;
    phone?: string | null;
  }

  const optionJobTitle = [
    { label: JobTitle.Developer, value: JobTitle.Developer },
    { label: JobTitle.Admin, value: JobTitle.Admin }
  ];

  // ToDO: In later stages, we're planning to handle it from the popup.
  const [showFormEdit, setShowFormEdit] = useState(false);
  const [orgEmail, setOrgEmail] = useState(orgPartnerCompanyEmail);
  const [name, setName] = useState(userName);
  const [role, setRole] = useState(orgJobTitle);
  const [orgName, setOrgName] = useState(orgPartnerCompanyName);
  const [email, setEmail] = useState(userEmail);
  const [errors, setErrors] = useState<formDetails>({});
  const [showLoader, setShowLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [phone, setPhone] = useState<string>(userPhone);
  const [isShowing, setIsShowing] = useState(false);

  const navigate = useNavigate();

  // Form validation code added to useState
  useEffect(() => {
    validateForm();
  }, [email, name, phone]);

  useEffect(() => {
    // Reset form fields when opening form
    if (showFormEdit) {
      setOrgEmail(orgPartnerCompanyEmail);
      setName(userName);
      setRole(orgJobTitle);
      setOrgName(orgPartnerCompanyName);
      setEmail(userEmail);
      setPhone(userPhone);
      setShowAlert(false);
    }
  }, [showFormEdit]);

  // Form validation code added here
  const validateForm = () => {
    const errors: formDetails = {};

    if (!name.trim()) {
      errors.name = 'Please provide a valid name';
    }
    if (email === null) {
      errors.usersEmail = 'Please provide a valid email';
    }
    setErrors(errors);
  };

  // Storing data from UI to db via promise
  const onSubmit: any = async (event: React.FormEvent) => {
    event.preventDefault();
    setShowLoader(true);
    // To show the loader after submitting the form
    setTimeout(() => setShowLoader(false), 3000);
    setTimeout(() => setShowFormEdit(false), 20000);
    sessionStorage.removeItem('Email');
    validateForm();
    // Form submission code here
    const userObj = new UpdateOrgUser({
      role,
      orgName,
      orgEmail,
      email,
      name,
      phone
    });

    // API responses have been merged since actions are same
    const updateStatus = await userObj.updateAllOrgUser();
    setOrgEmail(orgEmail);
    setName(name);
    setRole(role);
    setOrgName(orgName);
    setEmail(email);
    setPhone(phone);

    sessionStorage.setItem(`Update Name ${userEmail}`, name);
    sessionStorage.setItem(`Updated Role ${userEmail}`, role);

    const errorMessage = responseMessages.UserExists;
    const successMessage = responseMessages.UpdateUserSuccess;
    const response = handleApiResponse(updateStatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setResponseMessage(responseBody);
    setShowAlert(true);
    // checking current path and navigate conditonally.
    if (updateStatus.statusCode === 201) {
      if (location.pathname === '/btadmin') {
        navigate('/btadmin');
      } else {
        navigate('/admin');
      }
    }
  };

  // Wrap the handleButtonClick function in useCallback to show the update user form on clicking the edit button
  const handleButtonClick = useCallback(() => {
    setShowFormEdit((prevShowFormEdit) => !prevShowFormEdit);
  }, []);

  // Wrap the popupCloseHandler function in useCallback to close the create user form on clicking the close button
  const popupCloseHandler = useCallback(() => {
    setShowFormEdit(false);
  }, []);

  return (
    <Fragment>
      <Button
        onclick={handleButtonClick}
        disabled={disabled}
        className={disabled ? 'cursor-not-allowed' : ''}
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
      >
        <ImageTag src={edit} width={'20px'} alt={''} style={{ marginLeft: '1rem' }} name={'Edit'} />
        {isShowing && (
          <div className="group flex relative">
            <span
              className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2
        -translate-x-1/2 translate-y-full m-4 mx-auto"
            >
              Edit User
            </span>
          </div>
        )}
      </Button>
      {showFormEdit && (
        <CustomPopup onClose={popupCloseHandler} show={showFormEdit}>
          <Form
            orgEmail={orgEmail}
            userFname={name}
            setFname={setName}
            orgJobTitle={role}
            setJobTitle={setRole}
            setPhone={setPhone}
            phone={phone}
            orgPartnerCompanyName={orgName}
            showEditForm={showAlert}
            setShowFormEdit={setShowFormEdit}
            message={responseMessage}
            showLoader={showLoader}
            errors={errors}
            onSubmit={onSubmit}
            optionJobTitle={optionJobTitle}
            showFormEdit={showFormEdit}
            isBtAdmin={true}
            usersEmail={email}
            setUsersEmail={setEmail}
            isFeedback={false}
            createUser={function (e: React.FormEvent<Element>): void {
              throw new Error('Function not implemented.');
            }}
          />
        </CustomPopup>
      )}
    </Fragment>
  );
};
