import React, { Fragment, useContext, useEffect, useState } from 'react';
import UserList from '../components/AdminPanel/UserList';
import ModuleList from '../components/AdminPanel/ModuleList';
import Header from '../components/Header';
import AdminTopBar from '../components/TopBar/AdminTopBar';
import { LoginContext } from '../context/IppContext';
import { FederatedIdentity } from '../utils/AccessCredentials/FederatedIdentity';
import OrganizationUser from '../utils/OrganizationUser/OrganizationUser';
import { downloadFile } from '../utils/Helper/downloadFiles';
import Downloadfile from '../library/DownloadFiles/DownloadFile';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import withLogout from '../components/AutoLogOut/withLogout';
import AwsLogin from '../utils/AccessCredentials/AwsLogin';
import { AWS_SIGNIN_URL } from '../utils/Helper/helper';
import Loader from '../library/Loader/loader';
import { IPPFourthLevelHeading, IPPParagraph } from '../library/Heading/Heading';
import Button from '../library/Button/button';

const AdminPanel: React.FC<any> = () => {
  // useState hook to store the timeOfDay state variable
  const [timeOfDay, setTimeOfDay] = useState<string>('');
  const { loginToken, updateToken } = useContext(LoginContext);
  const [accessKey, setAccessKey] = useState<String>('');
  const [secret, setSecret] = useState<String>('');
  const [session, setSession] = useState<String>('');
  const [roleARN, setRoleArn] = useState<String>('');
  const [error, setError] = useState<string>(''); // New error state
  const parsedLoginToken = JSON.parse(loginToken);
  const userPoolId = parsedLoginToken.userPoolId;
  const identityPoolId = parsedLoginToken.IdentityPoolId;
  const federatedIdentity = new FederatedIdentity({
    userPoolId,
    identityPoolId
  });
  const navigate: NavigateFunction = useNavigate();
  const [isShowCred, setIsShowCred] = useState<boolean>(false);
  // Storing the user's name and displaying it as a welcome page
  const userGroup: string | null = sessionStorage.getItem('groupName');
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  // To handle show functionality
  const [numValuesToShow, setNumValuesToShow] = useState('');
  // To handle change based on event on show bar.
  const handleNumValuesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setNumValuesToShow(value);
  };
  const [dataUser, setDataUser]: any = useState([]);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [queryUser, setQueryUser] = useState('');
  // To handle show functionality
  const [numValuesToShowUser, setNumValuesToShowUser] = useState<string>('');
  // To handle change based on event on show bar.
  const handleNumValuesChangeUser = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setNumValuesToShowUser(value);
  };
  // If show values are empty, user will be able to see all table data. Given entries, use will see those particular entries only.
  const valuesToShow = numValuesToShowUser ? dataUser.slice(0, numValuesToShowUser) : dataUser;

  const getAdminUsers = async () => {
    const organizationAdminObj = new OrganizationUser();
    const { users } = await organizationAdminObj.getAllAdminUser();
    setDataUser(users);
    setIsLoadingUser(false);
  };

  const getAdminUsersModules = async () => {
    const organizationAdminObj = new OrganizationUser();
    const result = await organizationAdminObj.getAllAdminUserModule();
    setData(result);
    setIsLoading(false);
  };

  /* useEffect hook to fetch the list of users from a database using an OrganizationUser object and the getAllAdminUser method.
  Once the data is fetched, the state is updated with the setData function and the isLoading state is set to false after a delay of one second. */

  // function to clear storage when back or forward button is clicked from browser
  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      sessionStorage.clear();
      navigate('/login');
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      void getAdminUsers();
    }, 3000);
    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [getAdminUsers]);

  // Fetching data from db and mapping it to the array. Here the promise returned is void.
  useEffect(() => {
    void getAdminUsersModules();
  }, []);

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour >= 6 && hour < 12) {
      setTimeOfDay('Good morning');
    } else if (hour >= 12 && hour < 18) {
      setTimeOfDay('Good afternoon');
    } else {
      setTimeOfDay('Good evening');
    }
  }, []);

  // Download UserGuideBook and script.
  const downloadUserGuide = () => {
    const fileUrl = '/UserGuideBook.pdf';
    const fileName = 'UserGuideBook'; // Set the desired file name
    downloadFile(fileUrl, fileName);
  };
  const downloadScript = () => {
    const fileUrl = '/setup.sh';
    const fileName = 'setup.sh'; // Set the desired file name
    downloadFile(fileUrl, fileName);
  };

  const [companyName, setCompanyName] = useState<string>('');
  const [orgEmail, setOrgEmail] = useState<string>('');

  const userName = sessionStorage.getItem('adminFullName');

  // Function to update the companyName state variable
  const handleCompanyNameChange = (name: string) => {
    setCompanyName(name);
  };

  // Function to update the orgEmail state variable
  const handleOrgEmailChange = (email: string) => {
    setOrgEmail(email);
  };
  // This function is used to fetch federated session from backend and save it in component state
  const fetchFederatedSession = async () => {
    try {
      const idToken = parsedLoginToken.cognitoDetails.message.idToken.jwtToken;
      const role =
        parsedLoginToken.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const responseData = await federatedIdentity.fetchAWSCredentials(idToken, orgEmail, role);
      setAccessKey(responseData.body.data.Credentials?.AccessKeyId ?? '');
      setSecret(responseData.body.data.Credentials?.SecretAccessKey ?? '');
      setSession(responseData.body.data.Credentials?.SessionToken ?? '');
      setRoleArn(role);
      setIsShowCred(true);
    } catch (error) {
      setError('JSON parsing error: ' + error); // Handle JSON parsing error
    }
  };
  // this function is used to get sign-in token from backend and send it to aws federetion endpoint for console login.
  const getCredentials = async () => {
    try {
      const idToken = parsedLoginToken.cognitoDetails.message.idToken.jwtToken;
      const role =
        parsedLoginToken.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const responseData = await federatedIdentity.fetchAWSCredentials(idToken, orgEmail, role);
      const awsLoginObj = new AwsLogin({
        accessKey: responseData.body.data.Credentials?.AccessKeyId,
        secret: responseData.body.data.Credentials?.SecretAccessKey,
        session: responseData.body.data.Credentials?.SessionToken
      });
      const consolLoginStatus = await awsLoginObj.consoleLogin();
      if (consolLoginStatus.statusCode === 201) {
        const SigninToken = consolLoginStatus.body.SigninToken;
        // construct the url with signin token for aws login
        const getLoginUrl = `${AWS_SIGNIN_URL}${SigninToken}`;
        window.open(getLoginUrl, '_blank', 'noopener,noreferrer');
      }
    } catch {
      setError('error: in aws console login' + error); // Handle console login error
    }
  };
  const firstName = sessionStorage.getItem('adminFirstName');
  return (
    <Fragment>
      <Loader />
      <div className="overflow-hidden relative block">
        <Header>
          <AdminTopBar />
        </Header>
        <div className="ml-[300px] pt-[100px] z-0">
          <div className="pt-[30px] pr-[20px] pl-[20px]">
            <div className="p-0 pb-[30px] rounded-[5px] mx-0 flex flex-wrap">
              <div className="p-0 w-full">
                <IPPFourthLevelHeading
                  headerText={`${timeOfDay}, ${firstName} - (Organisation Admin)`}
                  className={'text-black text-[22px] mb-0.5 leading-[1.2]'}
                />
                <IPPFourthLevelHeading
                  headerText={`Organisation - ${userGroup}`}
                  className={'text-black text-[22px] mb-0.5 leading-[1.2]'}
                />
                <IPPParagraph
                  headerText={'Indigo Partner Portal | Admin Dashboard'}
                  className={'mb-0 text-[12px] mt-0 block'}
                />
              </div>
            </div>
          </div>
        </div>
        <Downloadfile onGuideBook={downloadUserGuide} onScript={downloadScript} />
        <div className="flex flex-col justify-start ml-[310px] mr-[10px] my-[10px]  p-4 border border-black-500 rounded-[5px] ">
          <Button
            onclick={getCredentials}
            className="bg-transparent w-48 hover:bg-blue-500 text-blue-700 font-semibold hover:text-black mt-2 py-2 px-2 border border-black-500 hover:border-solid rounded"
            buttonText="View in CodeCommit"
          />
          <Button
            onclick={fetchFederatedSession}
            className="bg-transparent w-48 hover:bg-blue-500 text-blue-700 font-semibold hover:text-black mt-2 py-2 px-2 border border-black-500 hover:border-solid rounded"
            buttonText="Get AWS Cred."
          />
          &nbsp;
          {isShowCred && (
            <>
              <IPPFourthLevelHeading
                id="aceeskeyheader"
                headerText={'AWS Credentials'}
                className={'text-black text-[16px] mb-1 leading-[1.2] font-bold'}
              />
              <IPPFourthLevelHeading
                id="aceeskeyheader"
                headerText={`aws_access_key_id: ${accessKey}`}
                className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
              />
              <IPPFourthLevelHeading
                id="aceeskeyheader"
                headerText={`aws_secret_access_key: ${secret}`}
                className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
              />
              <IPPParagraph
                id="aceeskeyheader"
                headerText={`aws_session_token: ${session}`}
                className={'text-black text-[16px] mb-0.5 leading-[1.2] break-words'}
              />
              <IPPFourthLevelHeading
                id="aceeskeyheader"
                headerText={`role_arn: ${roleARN}`}
                className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
              />
            </>
          )}
        </div>
        <div className="flex w-full ml-[300px] pt-[50px]">
          <div className=" md:flex lg:flex flex-row justify-start mt-8 lg:mt-18 w-[54%] pl-2.5 pr-5">
            <UserList
              tableName="UserTable"
              data={dataUser}
              isLoading={isLoadingUser}
              query={queryUser}
              setQuery={setQueryUser}
              numValuesToShow={numValuesToShowUser}
              handleNumValuesChange={handleNumValuesChangeUser}
              valuesToShow={valuesToShow}
              name={companyName}
              email={orgEmail}
              onCompanyEmailChange={handleOrgEmailChange}
              onCompanyNameChange={handleCompanyNameChange}
              partnerName={userName!}
            />
          </div>
          <div className="md:flex lg:flex flex-row justify-start mt-8 lg:mt-18 w-[26%] pr-2.5">
            <ModuleList
              key={data.key}
              data={data}
              isLoading={isLoading}
              query={query}
              setQuery={setQuery}
              numValuesToShow={numValuesToShow}
              handleNumValuesChange={handleNumValuesChange}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default withLogout(AdminPanel);
