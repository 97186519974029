import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import WelcomeHeader from '../components/TopBar/WelcomeHeader';
import InputField from '../library/inputField/inputField';
import Label from '../library/label/label';
import Button from '../library/Button/button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { IPPHeading, IPPSubHeading } from '../library/Heading/Heading';

const TermsAndConditions: React.FC<any> = () => {
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem('userEmail')) {
      navigate('/login');
    }
  }, []);

  return (
    <div className="w-full ">
      <Header>
        <WelcomeHeader />
      </Header>
      <div className="col-start-0 col-span-8 flex flex-col mx-4 break-words  lg:w-1/2 hyphens-auto lg:hyphens-none md:pt-9 lg:pt-0">
        <IPPHeading
          headerText={'Indigo Partner Portal'}
          className={
            'my-6 text-2xl text-black md:text-4xl lg:text-4xl font-bold lg:mt-5 lg:mb-2 lg:my-0'
          }
        />
        <IPPSubHeading
          headerText="Please read our terms conditions throughly and carefully. And accept it to proceed further."
          className={'text-black text-2xl lg:text-3xl font-medium mr-2 px-3.5 lg:mr-0'}
        />
      </div>
      <div className="w-full mx-[10px]">
        <iframe src="/IndigoPortalUserTerms.pdf" width="100%" height="600px mx-[10px]" />
      </div>
      <div className="flex flex-row justify-start mt-[10px] mx-[10px]">
        <InputField
          type="checkbox"
          className="text-blue-600"
          value="I accept the terms and conditions."
          checked={isAgreed}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            setIsAgreed(!isAgreed);
          }}
          id={'moduleName'}
        />
        <Label
          className="text-right  ml-[10px] float-left"
          LabelText="I accept the terms and conditions."
        />
      </div>
      <div className="flex flex-row justify-center mt-[10px]">
        <Button
          className={
            isAgreed
              ? 'p-0 tracking-normal mb-2 h-10 text-[#fff] text-[17px] w-[100px] shadow-none border border-login-bt-border cursor-pointer bg-black rounded-[5px]'
              : 'p-0 tracking-normal mb-2 h-10 text-[#fff] text-[17px] w-[100px] shadow-none border border-login-bt-border cursor-pointer bg-light-gray rounded-[5px]'
          }
          buttonText="Proceed"
          onclick={(e) => {
            console.log('login clicked');
            navigate('/mfa');
          }}
          disabled={!isAgreed}
        />
      </div>
    </div>
  );
};

export default TermsAndConditions;
